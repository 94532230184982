import ACSLayout from "@/layouts/ACSLayout";

const AcsDashboard = () => {
  return (
    <div>
      <ACSLayout />
    </div>
  );
};

export default AcsDashboard;
